import React , { useState }from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Vendors from './Vendors'; 
import ComponentProduct from './ComponentProduct'; 
import ComponentVendor from './ComponentVendor'; 
import Axios from "axios";

const AllComponentList = (props) =>{
    const baseProductsList = [
        {   productId: '',
            productName: 'Product',
            productModel: 'Model',
            compPerProduct: 'comp/product'
        },  
         
    ];
    const baseVendorsList = [
        {   vendorId: '',
            vendorName: 'Vendor',
            vendorCity: 'City'
        },  
         
    ];
     
    const [showResults, setShowResults] = React.useState(false);
    const [showResultVendors, setShowResultVendors] = React.useState(false);
    const [productsList, setProductsList] = React.useState(baseProductsList);
    const [vendorsList, setVendorsList] = React.useState(baseVendorsList);

    const Results = () => (
        // <ComponentProduct comp_id = { props.compName.comp_id } baseProductsList= { productsList } />
        <ComponentProduct comp_id = { props.compName.comp_id }  baseProductsList= { productsList }/>
    )
    const ResultVendors = () => (
        <ComponentVendor comp_id = { props.compName.comp_id } baseVendorsList= { vendorsList } />
    )
    
    const manageProductHandler = () => {
        const formdata = { 
            'comp_id' : props.compName.comp_id 
        };
        Axios({
            
			url: "http://localhost/oec_api_ci/index.php/Api/productsByComponent",
            //url: "http://optitecheyecare.in/oecapici/index.php/Api/productsByComponent",
			method: "post",
			responsetype: 'json',
            data: JSON.stringify(formdata)             
		})
        .then((res) => {        
            //console.log(res.data.results);    
            if(res.data.results != null){
                setProductsList (baseProductsList.concat (res.data.results));    
            }else{
                setProductsList (baseProductsList);
            }
            
            setShowResults(true);
            setShowResultVendors(false); 
            <Results />               
			
		})
			
		// Catch errors if any
		.catch((err) => {
           
		 });       
        
    }

    const manageVendorHandler = () => {
       
        
        const formdata = { 
            'comp_id' : props.compName.comp_id 
        };
        Axios({
            
			//url: "http://localhost/oec_api_ci/index.php/Api/vendorsByComponent",
            url: "http://optitecheyecare.in/oecapici/index.php/Api/vendorsByComponent",
			method: "post",
			responsetype: 'json',
            data: JSON.stringify(formdata)
             
		})
        .then((res) => {  
            if(res.data.results != null){
                setVendorsList (baseVendorsList.concat (res.data.results));  
            }          
              
            setShowResults(false);
            setShowResultVendors(true); 
            <Results />               
			
		})
			
		// Catch errors if any
		.catch((err) => {
           
		 });
              
        
    }

    return(
        <div className="container-fluid p-0">
        <div className="card">
            <div className="card-body">
            <div className="row">
            <form>
                <div className="row">
                <Table striped bordered hover>

<tbody>
<tr>
{/* <td>{props.compName.comp_name} | {props.compName.comp_unit}</td> */}
<td>{props.compName.comp_name} </td>
{/* <td><Button  onClick = { manageProductHandler } className="btn btn-lg btn-primary">Manage Products</Button></td> */}
<td><Button  onClick = { manageVendorHandler } className="btn btn-lg btn-primary">Manage Vendors</Button></td>


</tr>

</tbody>
</Table>
                </div>
                
            </form>
            </div>
             
            </div>
        </div>{ showResults ? <Results /> : null }  
              { showResultVendors ? <ResultVendors /> : null }  
        
    </div> 

    )
}
export default AllComponentList;
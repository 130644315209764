import React, { useState }  from "react";
import Axios from "axios";
import Button from 'react-bootstrap/Button';
import ComponentVendor from './ComponentVendor'; 



const ComponentMakeProductList = (props) =>{
    const baseVendorsList = [
        {   vendorId: '',
            vendorName: 'Vendor',
            vendorCity: 'City'
        },  
         
    ];

    const [componentDetails , setComponentDetails ]= useState ();
    const [showResults,setShowResults] = useState(false);
    const [vendorsList, setVendorsList] = React.useState(baseVendorsList);
    const [showResultVendors, setShowResultVendors] = React.useState(false);
    const [currentData, setCurrentData] = useState(
        {
            comp_name:'',
            comp_id:'',
            comp_unit : ''
        }       
    );

    const ResultVendors = () => (
        <ComponentVendor comp_id = { currentData.comp_id } comp_name = { currentData.comp_name } 
        comp_unit = { currentData.comp_unit } baseVendorsList= { vendorsList } />
    )
    
    const formdata = { 
        'product_id' : props.product_id 
    };

   
    if(showResults == false){
        Axios({
            //url: "http://localhost/oec_api_ci/index.php/Api/componentsVendorsByProducts",
            url: "http://optitecheyecare.in/oecapici/index.php/Api/componentsVendorsByProducts",
            method: "post",
            responsetype: 'json',
            data: JSON.stringify(formdata)        
           
        })        
           
        .then((res) => { 
            if(res.data.results != null){
                setComponentDetails(res.data.results);
                //console.log(componentDetails);
                setShowResults(true);                
            }
            
          
        })
            
        // Catch errors if any
        .catch((err) => {
            
        });
    }
    const manageVendorHandler = (componentData) => {
       
        //console.log(componentData);
        const formdata = { 
            'comp_id' : componentData.comp_id 
        };
        Axios({
            
			//url: "http://localhost/oec_api_ci/index.php/Api/vendorsByComponent",
            url: "http://optitecheyecare.in/oecapici/index.php/Api/vendorsByComponent",
			method: "post",
			responsetype: 'json',
            data: JSON.stringify(formdata)
             
		})
        .then((res) => {  
            if(res.data.results != null){
                 setVendorsList (baseVendorsList.concat (res.data.results));  
            }          
              
            setShowResults(false);
            setShowResultVendors(true); 
            setCurrentData(
                {
                    comp_name : componentData.comp_name, 
                    comp_id :  componentData.comp_id,           
                    comp_unit : componentData.comp_unit
                }
                
            );
            // <Results />               
			
		})
			
		// Catch errors if any
		.catch((err) => {
           
		 });
              
        
    }
    
    return(
        <div className="container-fluid p-0">
            <div className="card">
                <div className="card-body">
                { showResultVendors ? <ResultVendors /> : null }  
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="row p-2 bg-light border">
                                <div className='col-md-4 '>
                                Component
                                </div>
                               
                                <div className='col-md-4 '>
                                Vendor
                                </div>
                                <div className='col-md-4 '>
                                
                                </div>
                               
                            </div>                       
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 "> 
                        { showResults && 
                        
                            componentDetails.map (( componentData ) => (
                               
                            <div className="row p-2 bg-light border">
                            
                                
                                <div className='col-md-4 '>
                                    { componentData.comp_name }
                                </div>
                                
                                <div className='col-md-4 '>
                                    <select className='form-control form-control-lg'   >
                                    {componentData.vendors.map (( vendorsData ) => (
                                        <option value= { vendorsData.vendor_id }> { vendorsData.vendor_name } | { vendorsData.vendor_city }</option>   
                                        
                                    ))}
                                    </select>
                                    
                                </div>
                                <div className='col-md-4 '>
                                <Button  onClick =  { () => manageVendorHandler (componentData) }className="btn btn-lg btn-primary">Manage Vendors</Button>
                                </div>
                               
                          
                            </div> 
                        ))}              
                        
                             
                        </div>
                    </div>
                </div>           
            </div>
            
        </div>

    )
}
export default ComponentMakeProductList;
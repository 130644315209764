import React, { useState }  from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Axios from "axios";
import Button from 'react-bootstrap/Button';
import ComponentMakeProductList from './ComponentMakeProductList'; 
import Components from './Components'; 
import CompVendor from './CompVendor'; 



const MakeProduct = () => {
   
    const itemsOne = [
      {
        id:'',
        name:''
      }
    ];
    
    const [ productId , setProductId] = useState();
     
     
    const [showHtmlAddComponent, setShowHtmlAddComponent] = React.useState(false);
    const [showResults, setShowResults] = React.useState(false);
    const [items, setItems] = useState (itemsOne) ; 
   
  
    const handleOnHover = (result) => {      
      // the item hovered
      console.log(result)
    }
    
    const handleOnSelect = (item) => {
      setShowHtmlAddComponent(false);
      setShowResults(true);
      setProductId(item.id);
      
      <Results />     
    }
   
    
    const handleOnFocus = () => {
      
      setShowHtmlAddComponent(false);
      Axios({
        // url: "http://localhost/oec_api_ci/index.php/Api?action=Products",
       url: "http://optitecheyecare.in/oecapici/index.php/Api?action=Products",
        method: "GET",
        headers: {
          Accept: 'application/json',
                  'Content-Type': 'application/json',
          // Add any auth token here
          //authorization: "your token comes here",
        },
      
    })
		
      // Handle the response from backend here
      .then((res) => { 
          //console.log(res.data);                
          setItems (res.data.results);
            
      })
          
      // Catch errors if any
      .catch((err) => {
          
      });
    }
  
  
    const formatResult =(item) => {
      return (
        <>
          <span style={{ display: 'block', textAlign: 'left' }}>{item.name} : {item.model} </span>
        </>
      )
    }     

    const Results = () => (
        <ComponentMakeProductList product_id = { productId } />
    )
    const handleClick = () => {
      setShowResults(true);     
      setShowHtmlAddComponent(true);
      // setShowResultsTwo(false);        
  }
     //onClick = { manageProductHandler }      
    
    return(
        <div>
            <main className="content">

		        <div className="container-fluid p-0">
			        <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="row">
                              
                            
                              
                              <div className="col-md-6">
                                  <label className="form-label">Product</label>
                                  <ReactSearchAutocomplete
                                      
                                      items={items}
                                      
                                      onHover={handleOnHover}
                                      onSelect={handleOnSelect}
                                      onFocus={handleOnFocus}
                                      
                                      autoFocus                                      
                                      formatResult={formatResult}
                                      showNoResults={false}
                                      placeholder='Choose Product'
                                      styling={
                                          {
                                              height: "34.5px",
                                              borderRadius: "0.3rem"
                                          }
                                      }
                                  />                                   
                              </div>
                                
                              <div className="col-md-6">
                              { productId ? <Button   onClick={ handleClick } className='btn btn-lg btn-primary ' variant="primary">Add Component</Button>   : null }   
                                  
                              </div>
                            </div>
                          </div> 
                          {/* { showHtmlAddComponent ? <Components product_id = { productId } /> : null }  */}
                          { showResults ? <CompVendor stateChanger={setShowResults} product_id =  { productId } /> : null }   
                        </div>
                    </div>
                   
            
		        </div>
            
            {/* { showHtmlAddComponent ? <CompVendor stateChanger={setShowHtmlAddComponent} productId={ productId } /> : null }    */}
            { showResults ? <Results /> : null }
           
                


	        </main>
           
        </div>
        
    );
};
export default MakeProduct;
import React , { useState }from "react";
import AllComponentList from './AllComponentList'; 
import Button from 'react-bootstrap/Button';
import Axios from "axios";

const CompVendor = (props) => {
    
    //const [componentName , setComponentName] = useState();  
    const [componentId , setComponentId] = useState(); 
    //const [compPerProduct , setCompPerProduct] = useState(); 

    const [showResults, setShowResults] = React.useState(false);
    const [formData,setFormData] = useState({
        componentName : '',
        componentUnit : '',
        compPerProduct : ''
    } );
    const [addCompName, setAddCompName] = useState();
    const [units,setUnits] = useState ([
        {id:'',
            unit : '-- Select Unit --'
        }
    ] ); 
    const [flag,setFlag] = useState(false);
    if(flag ==false ){
        setFlag(true);
        Axios({
               
            // url: "http://localhost/oec_api_ci/index.php/Api?action=Units",
            url: "http://optitecheyecare.in/oecapici/index.php/Api?action=Units",
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        
        // Handle the response from backend here
        .then((res) => { 
           console.log(res.data);
            setUnits (units.concat(res.data.results));
                       
        })
            
        // Catch errors if any
        .catch((err) => {
            
        });
        
    }
    
    const onkeyHandler = (event) =>{

        setFormData({
            componentName : event.target.value,
            componentUnit : formData.componentUnit
        });
        setShowResults(false); 

        if(formData.componentName.trim().length > 1)  {
            setAddCompName (true);  
        }else{
            setAddCompName (false);             
        }          
    }
    const handleCancel = () =>{
        props.stateChanger(false);
    }
    const resdata = { 
        'comp_name' : formData.componentName ,
        'comp_unit' : formData.componentUnit ,
        'comp_id' : componentId 
    };
    const Results = () => (
       
        <AllComponentList compName = { resdata } />
    )
    const handleOnSelect = (event) => {
        setFormData({
            componentUnit : event.target.value,
            componentName : formData.componentName
        });
    }
    const handleUpdateQty = (event) =>{
        //setCompPerProduct(event.target.value);
        setFormData({   
            compPerProduct : event.target.value,
            componentUnit : formData.componentUnit,
            componentName : formData.componentName    
           
        });
    }
    const handleClick = () =>{
        const postdata = { 
            'comp_per_product' : formData.compPerProduct ,
            'comp_name' : formData.componentName,
            'comp_unit' : formData.componentUnit,
            'comp_product_id' : props.product_id
           
        };

        Axios({
            
			//url: "http://localhost/oec_api_ci/index.php/ApiI/user",
            url: "http://optitecheyecare.in/oecapici/index.php/ApiI/user",
			method: "post",
			responsetype: 'json',
            data: JSON.stringify(postdata)
             
		})
        .then((res) => { 
            //console.log(res.data.results);
            if(res.data.results.id>0){                
                setComponentId (res.data.results.id);               
                setShowResults(true);
                <Results />		
            }
			
		})
			
		// Catch errors if any
		.catch((err) => {

		 });
        
    }
    return(
        
        <div className="container-fluid p-0">
        <div className="card" style={{marginTop: "20px"}}>
            <div className="card-body">
           
            <form>
                <div className="row">
                    <div className="col-md-4">
                                        
                        <input  className="form-control form-control-lg" type="text"  onKeyUp={onkeyHandler}   name="component_name" placeholder="Type Component here" />
                    </div>
                    <div className="col-md-2">                                        
                        <select className='form-control form-control-lg'  onChange={handleOnSelect} >
                        {units.map (( unit ) => (
                            <option value= { unit.id }> { unit.unit }</option>   
                            
                        ))}
                        </select>
                    </div>
                    <div className='col-md-3'>
                        
                            <input type="text" className='form-control form-control-lg' onChange ={handleUpdateQty} /> 
                            
                        
                    </div>
                    <div className="col-md-3">
                        <Button disabled={!addCompName} onClick={ handleClick } className="btn btn-lg btn-primary">Add Me</Button>&nbsp;
                        <Button  className="btn btn-lg btn-danger" onClick={ handleCancel } >Cancel</Button>
                        
                    </div>
                </div>
            </form>
            
            </div>
            { showResults ? <Results /> : null } 
        </div>
       
         
    </div>
   
    )   

};
export default CompVendor; 
import React, { useState }  from "react";
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Button from 'react-bootstrap/Button';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';


const VendorsList = (props) =>{

const [vendorData, setVendorData] = useState(props.vendors);
//console.log(vendorData);

const columns = [
    { dataField: 'name', text: 'Vendor Name', sort: true },
    { dataField: 'city', text: 'Vendor City', sort: true }
   
];

const { SearchBar, ClearSearchButton } = Search;

const defaultSorted = [{
    dataField: 'name',
    order: 'asc'
  }];
const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
        //console.log('page', page);
        //console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
        console.log('page', page);
        console.log('sizePerPage', sizePerPage);
      }
    });


return(
      <div>
        <main className="content">
          <div className="container-fluid p-0"> 
            <ToolkitProvider
              bootstrap4
              keyField='id'
              data={vendorData}
              columns={columns}
              search
            >
            {
              props => (
                <div className="row">
                  <div className="col-md-6">
                    <h6>Type Vendor Name here:</h6>
                    <SearchBar className="form-control form-control-sm" {...props.searchProps} />
                    <ClearSearchButton style  = {{ marginLeft : '2.8rem' }} className='btn btn-sm btn-primary ' variant="primary" {...props.searchProps} />
                  </div>
                  <br /><br /><br /><br />
                  <BootstrapTable
                    defaultSorted={defaultSorted}
                    pagination={pagination}
                    {...props.baseProps}
                  />
                </div>       
              )
            }
            </ToolkitProvider>
          </div>
        </main>  
      </div>
  )
  }

export default VendorsList;
import { Link } from 'react-router-dom';
function Sidebar(props) {
    return(     
      	<nav id="sidebar" className="sidebar js-sidebar">
			<div className="sidebar-content js-simplebar">
				<a className="sidebar-brand" href="index.html">
          			<span className="align-middle">Optitech Eye Care</span>
        		</a>

				<ul className="sidebar-nav">
					

					<li className="sidebar-item active">
						<Link className="sidebar-link" to="/">
              				<i className="align-middle" data-feather="sliders"></i> <span className="align-middle">Dashboard</span>
            			</Link>
					</li>

					<li className="sidebar-item">
						<Link className="sidebar-link" to="/makeproduct">
              				<i className="align-middle" data-feather="user"></i> <span className="align-middle">Make Product</span>
            			</Link>
					</li>

					<li className="sidebar-item">
						<Link className="sidebar-link" to="/products">
              				<i className="align-middle" data-feather="user"></i> <span className="align-middle">Product</span>
            			</Link>
					</li>

					{/* <li className="sidebar-item">
						<Link className="sidebar-link" to="/components">
              				<i className="align-middle" data-feather="user"></i> <span className="align-middle">Components</span>
            			</Link>
					</li> */}

					<li className="sidebar-item">
						<Link className="sidebar-link" to="/vendors">
              				<i className="align-middle" data-feather="user"></i> <span className="align-middle">Vendors</span>
            			</Link>
					</li>

					

				
					{/* <li className="sidebar-header">
						Tools & Components
					</li>

					<li className="sidebar-item">
						<a className="sidebar-link" href="ui-buttons.html">
              <i className="align-middle" data-feather="square"></i> <span className="align-middle">Buttons</span>
            </a>
					</li>

					<li className="sidebar-item">
						<a className="sidebar-link" href="ui-forms.html">
              <i className="align-middle" data-feather="check-square"></i> <span className="align-middle">Forms</span>
            </a>
					</li>

					<li className="sidebar-item">
						<a className="sidebar-link" href="ui-cards.html">
              <i className="align-middle" data-feather="grid"></i> <span className="align-middle">Cards</span>
            </a>
					</li>

					<li className="sidebar-item">
						<a className="sidebar-link" href="ui-typography.html">
              <i className="align-middle" data-feather="align-left"></i> <span className="align-middle">Typography</span>
            </a>
					</li>

					<li className="sidebar-item">
						<a className="sidebar-link" href="icons-feather.html">
              <i className="align-middle" data-feather="coffee"></i> <span className="align-middle">Icons</span>
            </a>
					</li>

					<li className="sidebar-header">
						Plugins & Addons
					</li>

					<li className="sidebar-item">
						<a className="sidebar-link" href="charts-chartjs.html">
              <i className="align-middle" data-feather="bar-chart-2"></i> <span className="align-middle">Charts</span>
            </a>
					</li>

					<li className="sidebar-item">
						<a className="sidebar-link" href="maps-google.html">
              				<i className="align-middle" data-feather="map"></i> <span className="align-middle">Maps</span>
            			</a>
					</li> */}




				</ul>			
			</div>
		</nav>      
    );
}
export default Sidebar;
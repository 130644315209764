import React , { useState }from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Axios from "axios";
import Button from 'react-bootstrap/Button';
import ComponentProductList from './ComponentProductList'; 
import CompVendor from './CompVendor'; 
import AllComponentList from './AllComponentList'; 
import './ComponentAdd.css'; 

const Components = (props) => {
    
    const itemsOne = [
        
    ];    
    const [items, setItems] = useState (itemsOne) ;
    const [showResults, setShowResults] = React.useState(false);
    const [showResultsTwo, setShowResultsTwo] = React.useState(false);

    const [componentName , setComponentName] = useState();  
    const [componentId , setComponentId] = useState();
    const [componentUnit , setComponentUnit] = useState();
    
    
    const handleOnFocus = () => {
        setShowResults(false);
        setShowResultsTwo(false);
        if(items.length===0){
            Axios({
               
                //url: "http://localhost/oec_api_ci/index.php/Api?action=Components",
                url: "http://optitecheyecare.in/oecapici/index.php/Api?action=Components",
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // Add any auth token here
                    //authorization: "your token comes here",
                },
            
                // Attaching the form data
                //data: formData,
            })
            
            // Handle the response from backend here
            .then((res) => { 
                setItems (res.data.results);
                //console.log('len--'+items.length());
                //setShowResults(false);       
                    
            })
                
            // Catch errors if any
            .catch((err) => {
                
            });
        }
    }
    
    const handleOnSelect = (component_details) => {
        //component_details.target.blur()
        console.log(component_details);
      
        setComponentName(component_details.name);  
        setComponentId(component_details.id);
        setComponentUnit(component_details.unit);
        //setShowResults(false);
        
        setShowResultsTwo(true); 
       
        setTimeout(() => {
            component_details.currentTarget.blur() ;
        }, 10);
            
    }
    const resdata = { 
        'comp_name' : componentName ,
        'comp_id' : componentId,
        'comp_unit' : componentUnit,
       
    };
    
   
    const onkeyHandler = () => {
        //alert('sssss');
        setShowResultsTwo(false);  
    }

    const handleClick = () => {
       
        setShowResults(true);
        setShowResultsTwo(false);        
    }
    
    return (
        <div>
            

		        <div className="container-fluid p-0">
			        {/* <div className="card"> */}
                        <div className="card-body">
                        <div className="row">
                        
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="form-label">Component</label>
                                    <ReactSearchAutocomplete
                                        
                                        items={items}
                                        onFocus={handleOnFocus}  
                                        onSelect={handleOnSelect} 
                                        onSearch={ onkeyHandler }                           
                                        showNoResults={false}
                                        placeholder='Choose Component'
                                        
                                        styling={
                                            {
                                                height: "34.5px",
                                                borderRadius: "0.3rem"
                                            }
                                        }
                                    />
                                   
                                </div>
                               
                                <div className="col-md-3">
                                <br /><br />
                                <Button onClick={ handleClick } className='btn btn-sm btn-primary ' variant="primary">Add New Component</Button> 
                                {/* <Button onClick={ handleClick } className='btn btn-sm btn-primary' variant="primary">Show List</Button>                                      */}
                                </div>
                                
                                
                            </div>
                           
                            
                        

			            </div>
                        </div>
                    {/* </div> */}
		        </div>
               
                { showResults ? <CompVendor stateChanger={setShowResults} product_id = {props.product_id} /> : null }    
                { showResultsTwo ? <AllComponentList compName = { resdata } /> : null }   
                
	       
           
        </div>

    );
}
export default Components;
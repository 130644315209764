import React, { useState }  from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Axios from "axios";
import ComponentProductList from './ComponentProductList'; 


const Product = () => {
   
    const itemsOne = [
      {
        id:'',
        name:''
      }
    ];
      const [ productName , setProductName ]= useState ();
      const [ productModel , setProductModel ]= useState ();
      const [ productId , setProductId] = useState();
      const [ qty , setQty ] = useState();
     
      
      const [items, setItems] = useState (itemsOne) ; 
      const handleOnSearch = (string, results) => {
        setShowResults(false);
        setQty(0);
      }
    
      const handleOnHover = (result) => {
        // the item hovered
        console.log(result)
      }
    
      const handleOnSelect = (item) => {
        // the item selected
        setShowResults(false);
        setProductName(item.name);
        setProductModel(item.model);
        setProductId(item.id);
      }
    
      const handleOnFocus = () => {
        
       
        Axios({
          //url: "http://localhost/oec_api_ci/index.php/Api?action=Products",
          url: "http://optitecheyecare.in/oecapici/index.php/Api?action=Products",
          method: "GET",
          headers: {
            Accept: 'application/json',
                    'Content-Type': 'application/json',
            // Add any auth token here
            //authorization: "your token comes here",
          },
        
      })
		
            // Handle the response from backend here
            .then((res) => { 
                //console.log(res.data);                
                setItems (res.data.results);
               		
            })
                
            // Catch errors if any
            .catch((err) => {
                
            });
      }
      const [showResults, setShowResults] = React.useState(false)
    
      const formatResult =(item) => {
        return (
          <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name} : {item.model} </span>
          </>
        )
      }

      const onkeyHandler = (event) => { 
         
       
        if(event.target.value > 0){
            setQty(event.target.value);
            setShowResults(true);
            <Results />
        }else{
            setShowResults(false);
        }
    }

        const Results = () => (
           <ComponentProductList product_id = { productId } quantity = { qty } />
        )
          
    
    return(
        <div>
            <main className="content">

		        <div className="container-fluid p-0">
			        <div className="card">
                        <div className="card-body">
                        <div className="row">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="form-label">Product</label>
                                    <ReactSearchAutocomplete
                                        
                                        items={items}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus                                      
                                        formatResult={formatResult}
                                        showNoResults={false}
                                        placeholder='Choose Product'
                                        styling={
                                            {
                                                height: "34.5px",
                                                borderRadius: "0.3rem"
                                            }
                                        }
                                    />
                                   
                                </div>
                                <div className="col-md-1">
                                    <label className="form-label">Qty</label>
                                    <input className="form-control form-control-lg" type="text" onKeyUp={onkeyHandler}  name="company"
                                        placeholder="Qty" />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label"></label>
                                    <span style={{ display: 'block', textAlign: 'left' }}>Product : <b>{productName}  </b></span>
                                    <span style={{ display: 'block', textAlign: 'left' }}>Model : <b>
                                    {productModel}  </b>  </span>
                                   
                                    
                                </div>
                            </div>
                           
                            
                        </form>
			            </div>
                        </div>
                    </div>
		        </div>
                { showResults ? <Results /> : null }
                


	        </main>
           
        </div>
        
    );
};
export default Product;
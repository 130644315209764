import React, { useState,useRef }  from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Axios from "axios";
import Button from 'react-bootstrap/Button';
import Messages from '../common/Messages';
import Dialog from '../common/Dialog';


const ComponentProduct = (props) => {
    const itemsOne = [];
   
    const [productList, setProductList] = useState(props.baseProductsList);
   
    const [items, setItems] = useState (itemsOne); 
    const defaultMsgData =   {   
        message: 'Product Added Succesfully',
        varient: 'success'
    };
    const [msgData,setMsgData]=useState (defaultMsgData);
    const [show, setShow] = useState(false)  ;   
    
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        //Update
        nameProduct: ""
      });
      const idProductRef = useRef();
      const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
          message,
          isLoading,
          //Update
          nameProduct
        });
      }; 
    
//console.log(productList);
    const handleOnFocus = () => {
        if(items.length===0){
            Axios({
                //https://optitecheyecare.in/oecapici/index.php/Api?action=Products
                // Endpoint to send files
                //url: "https://www.maxwellrelocations.com/max_ai/common.php?action=ViewList&booking_date1=25-Aug-2021&booking_date2=31-Aug-2021&bookingPlace=1",
                url: "http://optitecheyecare.in/oecapici/index.php/Api?action=Products",
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',                   
                },        
               
            })
            
               
            .then((res) => { 
                //console.log(res.data); setItems
                setItems (res.data.results);
              
            })
                
            // Catch errors if any
            .catch((err) => {
                
            });
        }
    }
    
    const addProduct = () => {
        //console.log( props.comp_id);
        const formdata = { 
            'comp_id' : props.comp_id,
            'productsRecord' : productList
        };
        Axios({
            
            url: "http://optitecheyecare.in/oecapici/index.php/ApiI/addProductsToComponent",
            //url: "http://localhost/oec_api_ci/index.php/ApiI/addProductsToComponent",
            method: "post",
			responsetype: 'json',
            data: JSON.stringify(formdata)
        
            // Attaching the form data
            //data: formData,
        })
        
        // Handle the response from backend here
        .then((res) => { 
            
            setShow(true);           
                
        })
            
        // Catch errors if any
        .catch((err) => {
            //  console.log(err);
        });
    }
    const handleUpdateQty = (event,id) =>{
        
        const indexValue = productList.findIndex( e => e.productId == id );
       //console.log(indexValue);
        let items = [...productList];
       
        let item = {...items[indexValue]};
        
        item.compPerProduct=event.target.value;
        items[indexValue]=item;
        //console.log(items);
        setProductList(items);
    }

    const handleOnSelect = (item) => {
        
        if(productList.findIndex( e => e.productId == item.id ) === -1 ){
            // console.log(item);
            const newList = productList.concat ({
                productName: item.name,
                productModel: item.model,
                compPerProduct: '',
                productId: item.id
            });
            
           
            setProductList(newList);
           
            
        }else{
            //console.log('Print Error Message');
        }
        
        
    }
    const handleDelete = (id) => {
        const index = productList.findIndex((p) => p.productId === id);
    
        handleDialog("Are you sure you want to delete?", true, productList[index].productName);
        idProductRef.current = id;
      };
      const areUSureDelete = (choose) => {
        if (choose) {
            setProductList(productList.filter((p) => p.productId !== idProductRef.current));          
        } 
        handleDialog("", false);
      };
    
    const formatResult =(item) => {
        return (
          <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name} : {item.model} </span>
          </>
        )
      }
    
    
    return(
        <>
        <div className="container-fluid p-0">
            <div className="card">
            <div className="card-body">

                <Messages stateChangerShowHide={ setShow } showMessage = {show} msgData = { msgData } />
                <div className="row">
              
                    <div className="col-md-5 " >
                    <label className="form-label">Choose Product</label>
                        <ReactSearchAutocomplete
                            
                            items={items}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            //autoFocus                                      
                            formatResult={formatResult}
                            showNoResults={false}
                            placeholder='Choose Product'
                            styling={
                                {
                                    height: "34.5px",
                                    borderRadius: "0.3rem"
                                }
                            }
                        />
                    </div>
                    <div className="col-md-7 ">
               
                    {productList.map (( productData ) => (
                        
                        <div className="row p-2 bg-light border">
                            <div className='col-md-5 '>
                               {productData.productName}
                               
                            </div>
                            <div className='col-md-3'>
                                {productData.productModel}
                            </div>
                            <div className='col-md-3'>
                                { productData.productId > 0 ? ( <input type="text" className='form-control form-control-sm' onChange ={(e) => {handleUpdateQty(e, productData.productId); }} value={productData.compPerProduct}  /> ) : (
                                    <>{productData.compPerProduct} </>
                                )}
                            </div>
                            
                            { productData.productId > 0 && 
                                <div className='col-md-1'>
                                    <i onClick={() => handleDelete(productData.productId)} id={productData.productId} className="fa fa-trash" aria-hidden="true"></i>
                                </div>
                            }                            
                        </div>                  
                        
                    ))}

                    {dialog.isLoading && (
                        <Dialog
                        //Update
                        nameProduct={dialog.nameProduct}
                        onDialog={areUSureDelete}
                        message={dialog.message}
                        />
                    )}
                    
                    <div className="row ">
                        <div className="col-md-12 text-center"><br />
                        <Button onClick = { addProduct }  className="btn btn-md btn-primary ">Confirm</Button>
                        </div>
                    </div>
                        
                    </div>
                </div>             
            </div>
        </div>
    </div>
    </>
    )
}
export default ComponentProduct ;

import React , { useState }from "react";
import Button from 'react-bootstrap/Button';
import AddVendor from './AddVendor'; 
import Axios from "axios";

import VendorsList from './VendorsList'; 
// import Axios from "axios";
// import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const Vendors = () => {
    const [showResults, setShowResults] = React.useState(false);
    const [showVendorsList, setShowVendorsList] = React.useState(false);
    
    const listHeaders=[
        {
            id : "Vendor Id",
            name : "Vendor Name",
            city : "Vendor City"
        } ]
    const [vendorsList,setVendorsList] = useState(listHeaders);
    const [ showResultVendors, setShowResultVendors] = useState(false);
    const handleClick = () => {
        
        setShowResults(true);             
    }
    
    if(vendorsList.length === 1){

        Axios({
                
            url: "http://optitecheyecare.in/oecapici/index.php/Api?action=Vendors",
            //url: "http://localhost/oec_api_ci/index.php/Api?action=Vendors",
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',                   
            },        
           
        })
        
           
        .then((res) => { 
            console.log(res.data.results);      
            
            if(res.data.results != null){
                setVendorsList (listHeaders.concat (res.data.results));  
                setShowVendorsList(true);             
            }
              
        })
            
        // Catch errors if any
        .catch((err) => {
            
        });
    
    }      
   
    return(
        <div>
        <main className="content">

            <div className="container-fluid p-0">
                {/* <div className="card"> */}
                    {/* <div className="card-body"> */}
                    
                        <div className="row">
                            <div className="col-md-6">
                                
                               
                            </div>
                            <div className="col-md-3">
                           
                            </div>
                            <div className="col-md-3">
                            <Button   onClick={ handleClick } className='btn btn-sm btn-primary ' variant="primary">Add New Vendor</Button>  &nbsp;
                           
                            </div>                
                            
                        </div>   &nbsp;&nbsp;
                    {/* </div> */}
                {/* </div> */}

                {/* <div className="card">
                    <div className="card-body"> */}
                    
                            { showResultVendors ? <VendorsList /> : null }        
                    {/* </div>
                </div> */}

            </div>
           
            { showResults ? <AddVendor vendorAdder={setVendorsList} stateChanger={setShowResults} vendors =  { vendorsList } /> : null }            
            { showVendorsList ? <VendorsList vendors =  { vendorsList } /> : null }             
            
        </main>
       
    </div>
    );
    
    
}
export default Vendors;
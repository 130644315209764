import Alert from 'react-bootstrap/Alert';
import React, { useState }  from "react";



function Messages(props) {
    
    if(props.showMessage){


    return (
           <div className="row">                    
           <Alert variant= {props.msgData.varient} onClose={() => props.stateChangerShowHide(false)} dismissible > {props.msgData.message}</Alert>  
           
           </div>
        );
    } 
    return(  
        <>  
        
        </>  
      )  

}

export default Messages;
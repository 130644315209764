import React, { useState }  from "react";
import Axios from "axios";



const ComponentList = (props) =>{
    const [componentDetails , setComponentDetails ]= useState ();
    const [showResults,setShowResults] = useState(false);
    const formdata = { 
        'product_id' : props.product_id 
    };
    if(showResults == false){
        Axios({
            //url: "http://localhost/oec_api_ci/index.php/Api/componentsVendorsByProducts",
            url: "http://optitecheyecare.in/oecapici/index.php/Api/componentsVendorsByProducts",
            method: "post",
            responsetype: 'json',
            data: JSON.stringify(formdata)        
           
        })
        
           
        .then((res) => { 
            if(res.data.results != null){
                setComponentDetails(res.data.results);
                console.log(res.data.results);
                setShowResults(true);
                
            } 
            
            
          
        })
            
        // Catch errors if any
        .catch((err) => {
            
        });
    }
    
    return(
        <div className="container-fluid p-0">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="row p-2 bg-light border">
                                <div className='col-md-4 '>
                                Component
                                </div>
                                <div className='col-md-3 '>
                                Qty
                                </div>
                                <div className='col-md-5 '>
                                Vendor
                                </div>
                               
                            </div>                       
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 "> 
                        { showResults && 
                        
                            componentDetails.map (( componentData ) => (
                               
                            <div className="row p-2 bg-light border">
                            
                                
                                <div className='col-md-4 '>
                                    { componentData.comp_name }
                                </div>
                                <div className='col-md-3 '>
                                    { props.quantity * componentData.comp_per_product  } { componentData.comp_unit}
                                </div>
                                <div className='col-md-5 '>
                                    <select className='form-control form-control-lg'   >
                                    {componentData.vendors.map (( vendorsData ) => (
                                        <option value= { vendorsData.vendor_id }> { vendorsData.vendor_name } | { vendorsData.vendor_city }</option>   
                                        
                                    ))}
                                    </select>
                                    
                                </div>
                               
                          
                            </div> 
                        ))}              
                        
                             
                        </div>
                    </div>
                </div>           
            </div>
        </div>

    )
}
export default ComponentList;
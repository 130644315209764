import React, { useState,useRef}  from "react";
import Axios from "axios";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Button from 'react-bootstrap/Button';
import Messages from '../common/Messages';
import Dialog from '../common/Dialog';
import Table from 'react-bootstrap/Table';

const ComponentVendor = (props) => {    
console.log(props);
    const [vendorList, setVendorList] = useState(props.baseVendorsList);
    const itemsOne = [];
   
    const [items, setItems] = useState (itemsOne); 
    const [vendorName , setVendorName ]= useState ();
    const [vendorCity , setVendorCity ]= useState ();
    const handleOnSearch = (string, results) => {

        
       
    }

    const defaultMsgData =   {   
        message: 'Vendor Added Succesfully',
        varient: 'success'
    };
    const [msgData,setMsgData]=useState (defaultMsgData);
    const [show, setShow] = useState(false)  ;
    
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        //Update
        nameProduct: ""
    });
    const idProductRef = useRef();
    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            //Update
            nameProduct
        });
    }; 

    const handleOnFocus = () => {
        if(items.length===0){
            Axios({
                
                url: "http://optitecheyecare.in/oecapici/index.php/Api?action=Vendors",
                //url: "http://localhost/oec_api_ci/index.php/Api?action=Vendors",
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',                   
                },        
               
            })
            
               
            .then((res) => { 
                setItems (res.data.results);              
            })
                
            // Catch errors if any
            .catch((err) => {
                
            });
        }
    }
    const addVendor = () => {
        //console.log( props.comp_id);
        const formdata = { 
            'comp_id' : props.comp_id,
            'vendorsRecord' : vendorList
        };
        Axios({
            
            url: "http://optitecheyecare.in/oecapici/index.php/ApiI/addVendorsToComponent",
            //url: "http://localhost/oec_api_ci/index.php/ApiI/addVendorsToComponent",
            method: "post",
			responsetype: 'json',
            data: JSON.stringify(formdata)
        })
        
        // Handle the response from backend here
        .then((res) => { 
             setShow(true);  
                
        })
            
        // Catch errors if any
        .catch((err) => {
            //  console.log(err);
        });
    }

    const handleOnSelect = (item) => {
       
        if(vendorList.findIndex( e => e.vendorId == item.id ) === -1 ){

            const newList = vendorList.concat ({
                vendorName: item.name,
                vendorCity: item.city,
                vendorId: item.id
            });
            
             setVendorCity(item.city);
            // setProductModel(item.model);
            setVendorList(newList);
           
            
        }else{
            //console.log('Print Error Message');
        }
       
        
    }

    const handleDelete = (id) => {
        const index = vendorList.findIndex((p) => p.vendorId === id);
    
        handleDialog("Are you sure you want to delete?", true, vendorList[index].vendorName);
        idProductRef.current = id;
    };
    const areUSureDelete = (choose) => {
        if (choose) {
            setVendorList(vendorList.filter((p) => p.vendorId !== idProductRef.current));          
        } 
        handleDialog("", false);
    };
    const removeVendor = (event) => {
      
        const myArray=vendorList.filter(function(obj){
           return obj.vendorId !== event.target.id;
        });       
        
        setVendorList( myArray);       
        
    }
    const formatResult =(item) => {
    return (
            <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name} : {item.model} </span>
            </>
        )
    }
    
    
    return(
        <>
        <div className="container-fluid p-0">
            <div className="card">
            <div className="card-body">
            <Messages stateChangerShowHide={ setShow } showMessage = {show} msgData = { msgData } />
                <Table striped bordered hover>
                    <tbody>
                        <tr>
                            <td></td>
                            
                        </tr>
                    </tbody>
                </Table>
                <div className="row">

                    <div className="col-md-5 " >
                    <label className="form-label">Choose Vendor</label>
                        <ReactSearchAutocomplete 
                          
                            items={items}
                            onSearch={handleOnSearch}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            // //autoFocus                                      
                            // formatResult={formatResult}
                            // showNoResults={false}
                           
                            placeholder='Choose Vendor'
                            styling={
                                {
                                    height: "34.5px",
                                    borderRadius: "0.3rem"
                                }
                            }
                        />
                    </div>
                    <div className="col-md-7 ">
                        {vendorList.map (( productData ) => (
                        
                            <div className="row p-2 bg-light border">
                                <div className='col-md-7 '>
                                {productData.vendorName}
                                
                                </div>
                                <div className='col-md-4'>
                                {productData.vendorCity}
                                </div>
                                
                                { productData.vendorId > 0 && 
                                    <div className='col-md-1'>
                                    {/* { <i onClick = { removeVendor } id={productData.vendorId} className="fa fa-trash" aria-hidden="true"></i> } */}
                                    <i onClick={() => handleDelete(productData.vendorId)} id={productData.vendorId} className="fa fa-trash" aria-hidden="true"></i>
                                    </div>
                                }

                                
                            </div>                  
                            
                        ))}

                        {dialog.isLoading && (
                            <Dialog
                            //Update
                            nameProduct={dialog.nameProduct}
                            onDialog={areUSureDelete}
                            message={dialog.message}
                            />
                        )}
                    
                        <div className="row ">
                            <div className="col-md-12 text-center"><br />
                            <Button   onClick = { addVendor }  className="btn btn-md btn-primary ">Confirm</Button>
                            
                            </div>
                        </div>
                        
                    </div>
            
                </div>
             
            </div>
        </div>
    </div>
        </>
    )
}

export default ComponentVendor ;

function Main(){
 return(
    <main className="content">
		{/* <div className="container-fluid p-0">
			<div className="card">
							<div className="card-body">
								<div className="row">
			<form>
				<div className="row">
				<div className="col-md-4">
					<label className="form-label">Name</label>
					<input className="form-control form-control-md" type="text" name="name"
						placeholder="Enter your name" />
				</div>
				<div className="col-md-4">
					<label className="form-label">Company</label>
					<input className="form-control form-control-lg" type="text" name="company"
						placeholder="Enter your company name" />
				</div>
				<div className="col-md-4">
					<label className="form-label">Email</label>
					<input className="form-control form-control-lg" type="email" name="email"
						placeholder="Enter your email" />
				</div>
				
				<div className="col-md-4">
					<label className="form-label">Password</label>
					<input className="form-control form-control-lg" type="password" name="password"
						placeholder="Enter password" />
				</div>
				
				
				
				<div className="col-md-4">
					<label className="form-label">Password</label>
					<input className="form-control form-control-lg" type="password" name="password"
						placeholder="Enter password" />
				</div></div>
				<div className="text-center mt-3">
					<a href="index.html" className="btn btn-lg btn-primary">Sign up</a>
					
				</div>
			</form>
			</div></div></div>	

		</div> */}
	</main>
 );  
}
export default Main;
import './App.css';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/common/Footer';
import Sidebar from './components/common/Sidebar';
import Main from './components/Main';
import Login from './components/Login';
import Topbar from './components/common/Topbar';
import React,{ Fragment } from 'react';
import Product from './components/Products/Product';
import MakeProduct from './components/Products/makeproduct';
import Components from './components/Products/Components';
import Vendors from './components/Products/Vendors';




function App() {
  let isLoggedIn = false;
  console.log(localStorage.getItem('userid'));
  if(localStorage.getItem('userid') ==='1' ){
    isLoggedIn = true;
  }
  console.log("isLoggedIn-->"+isLoggedIn);
  if(isLoggedIn){
    return (
      <Router>
        <Fragment>
          <div className="wrapper">
            <Sidebar />
              <div className="main">
                  <Topbar />
                  <Routes>
                      <Route path='/' element={<Main /> } /> 
                      <Route path='/makeproduct' element={<MakeProduct /> } />                       
                      <Route path='/products' element={<Product /> } />                       
                      <Route path='/components' element={<Components /> } />                       
                      <Route path='/vendors' element={<Vendors /> } />                       
                  </Routes>                  
                  <Footer />              
              </div>
          </div>   
        </Fragment>       
      </Router>         
    );
  }else{
    return (
      <div className="wrapper">
        <Router>
          <Fragment>
            <Routes>
              <Route path='/' element={<Login /> } />
              <Route path='/login' element={<Login /> } />                       
            </Routes>  
          </Fragment>
        </Router>
      </div>  
    );
  }
}
export default App;

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Axios from "axios";
const Login = () => {
	
	const [enteredEmail,setEneteredEmail]=useState('info@optitecheyecare.com');
    const [enteredPassword,setEneteredPassword]=useState('12345');

	//let navigate = useNavigate();

	let navigate = useNavigate();
  
	const logoImage='../images/optitech-logo.png';

	const emailChangeHandler = (event) =>{
        setEneteredEmail(event.target.value);
	}

	const passwordChangeHandler = (event) =>{
        setEneteredPassword(event.target.value);
	}

	const submitHandler = (event) => {
        event.preventDefault();
        const formData = {
            email:enteredEmail,
            password:enteredPassword
        };
       	//console.log(formData);
       	//props.onSaveExpenseData(logindata);

		Axios({
	
			// Endpoint to send files
			//url: "http://localhost/optitech.php",
			url: "http://optitecheyecare.in/oecapici/index.php/Api?action=Products",
			method: "GET",
			headers: {
				Accept: 'application/json',
                'Content-Type': 'application/json',
				// Add any auth token here
				//authorization: "your token comes here",
			},
		
			// Attaching the form data
			
			//data: formData, // uncomment while login
		})
		
		// Handle the response from backend here
		.then((res) => { 
			//console.log(res);
			localStorage.setItem('userid','1');
			//navigate('/arun');	
			window.location.href='/';			
		})
			
		// Catch errors if any
		.catch((err) => {

		 });

	};
	
	return(
		<div className="main">
		<main className="d-flex w-100">
			<div className="container d-flex flex-column">
				<div className="row vh-100">
					<div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
						<div className="d-table-cell align-middle">

							<div className="text-center mt-4">
								
								<p className="lead">
									Sign in to your account to continue
								</p>
							</div>

							<div className="card">
								<div className="card-body">
									<div className="m-sm-4">
										<div className="text-center">
											<img src={logoImage} alt="Charles Hall" className="img-fluid rounded-circle" width="132" height="132" />
										</div>
										<form >
											<div className="mb-3">
												<label className="form-label">Email</label>
												<input className="form-control form-control-lg" type="email" name="email" value={enteredEmail} placeholder="Enter your email" onChange={emailChangeHandler} />
											</div>
											<div className="mb-3">
												<label className="form-label">Password</label>
												<input className="form-control form-control-lg" type="password" name="password" value={enteredPassword} placeholder="Enter your password" onChange={passwordChangeHandler} />
											</div>
											<div className="text-center mt-3">
												{/* <a href="index.html" className="btn btn-lg btn-primary">Sign in</a> */}
												<button type="button" onClick={submitHandler}  className="btn btn-lg btn-primary">Sign in</button> 
											</div>
										</form>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</main>
		</div>
	);  
}
export default Login;
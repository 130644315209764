import Button from 'react-bootstrap/Button';
function Dialog({ message, onDialog, nameProduct }) {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(0,0,0,0.5)"
      }}
      onClick={() => onDialog(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          background: "white",
          padding: "10px",
          borderRadius: "5px"
        }}
      >
        <div stlye={{ color: "#222e3c",fontSize: "15 px" }}>{message}</div>
        <div style={{ color: "#222e3c",fontWeight: 600, fontSize: "10 px" }}>{nameProduct}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
         
          <Button onClick={() => onDialog(true)} type="button" style={{marginRight: '4px'}} className="btn btn-md btn-success ">Yes</Button>
          <Button onClick={() => onDialog(false)} type="button" style={{marginRight: '4px'}} className="btn btn-md btn-primary ">No</Button>
        </div>
      </div>
    </div>
  );
}
export default Dialog;

import React , { useState }from "react";
import Button from 'react-bootstrap/Button';
import Axios from "axios";
import Messages from '../common/Messages';


const AddVendor = (props) => {
    
    const [vendorName,setVendorName]= useState();
    const [vendorCity,setVendorCity]= useState();
    
    const defaultMsgData =   {   
        message: 'Vendor Added Succesfully',
        varient: 'success'
    };
    const [msgData,setMsgData]=useState (defaultMsgData);
    const [show, setShow] = useState(false)  ; 
    
    const handleCancel = () =>{
        props.stateChanger(false);
    }

    const onkeyVendorNameHandler = (event) =>{
        setVendorName(event.target.value);      
    }
    const onkeyVendorCityHandler = (event) =>{
        setVendorCity(event.target.value);      
    }
     
    const handleClick = (event) =>{
            
            
        const formdata = { 
            'vendor_name' : vendorName,
            'vendor_city' : vendorCity 
        };
        Axios({
            
            //url: "http://localhost/oec_api_ci/index.php/ApiI/addVendor",
            url: "http://optitecheyecare.in/oecapici/index.php/ApiI/addVendor",
            method: "post",
            responsetype: 'json',
            data: JSON.stringify(formdata)
            
        })
        .then((res) => { 
            console.log(res);
           
            if(res.data.results.id>0){
                setShow(true);
                const addedVendor=
                    {
                        id : res.data.results.id,
                        name : vendorName,
                        city : vendorCity
                    }   
                   
                props.vendorAdder(props.vendors.concat (addedVendor));          
                	
            }
            
        })
            
        // Catch errors if any
        .catch((err) => {

        });

   }
   
    return(
        <> 
        <div className="container-fluid p-0">
        <div className="card">
            <div className="card-body">
            <Messages stateChangerShowHide={ setShow } showMessage = {show} msgData = { msgData } />
                <div className="row"></div>
            <form>
                <div className="row">
                    <div className="col-md-6">
                                        
                        <input className="form-control form-control-lg" type="text" name="v_name" onKeyUp={ onkeyVendorNameHandler } placeholder="Type Vendor here" />
                        
                    </div>
                    <div className="col-md-3">
                        <input className="form-control form-control-lg" type="text"  name="v_city" onKeyUp={ onkeyVendorCityHandler } placeholder="Type City here" />
                    </div>
                    <div className="col-md-3">
                        <Button  className="btn btn-lg btn-primary" onClick={ handleClick } >Add Me</Button>&nbsp;
                        <Button  className="btn btn-lg btn-danger" onClick={ handleCancel } >Cancel</Button>
                        
                    </div>
                </div>
            </form>
            
            </div>
        </div>
       
        {/* { showResults ? <Results /> : null }    */}
    </div>
   
     </>
    )   

};
export default AddVendor; 